import React, { useEffect, useState } from 'react'
import slugify from 'slugify'
import ScrollTo from 'src/components/ScrollTo'

import Home from '@interco/icons/build-v4/orangeds/MD/home'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import textEN from '../assets/data/hero-financie-seu-imovel-en.json'
import textPT from '../assets/data/hero-financie-seu-imovel-pt.json'

import { ILangFinancimentoEua } from '../../types'

import { BackHeader, Section } from './style'

const HeroYellowFi = ({ lang }: ILangFinancimentoEua) => {
  const [ iWb, setIWb ] = useState<typeof import("@interco/inter-webview-bridge")>()
  const pageText = lang === 'pt' ? textPT : textEN

  useEffect(() => {
    import('@interco/inter-webview-bridge').then((interBridge: typeof import("@interco/inter-webview-bridge")) => {
      setIWb(interBridge)

      const bridgeInstance = interBridge.default.getInstance()
      bridgeInstance.addWebviewEventListener(interBridge.WbSessionActions.GO_BACK, interBridge.interWbNavigate.requestGoBack)
      return () => bridgeInstance.removeWebviewEventListener(interBridge.WbSessionActions.GO_BACK, interBridge.interWbNavigate.requestGoBack)
    })
  }, [])

  return (
    <>
      <BackHeader className='col-12 px-3 cursor-pointer'>
        <div onClick={() => iWb?.interWbNavigate.requestGoBack()}>
          <OrangeIcon icon='arrow-left' color='#FF7A00' size='MD' />
        </div>
        <div
          className='cursor-pointer'
          onClick={() => iWb?.interWbNavigate.openDeepLink('bancointer://globalAccount/home')}
        >
          <Home height={24} width={24} color='#FF7A00' />
        </div>
      </BackHeader>
      <Section id={slugify(pageText.title).toLowerCase()} className='py-5 py-md-3 d-flex align-items-md-center'>
        <div className='container d-flex align-items-center justify-content-md-end'>
          <div className='row'>
            <div className='col-12 col-md-7 col-lg-8 col-xl-7 offset-md-4 offset-xl-5'>
              <h1
                className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'
                dangerouslySetInnerHTML={{ __html: pageText.title }}
              />
              <p
                className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mt-3 mb-3 pb-3'
                dangerouslySetInnerHTML={{ __html: pageText.description }}
              />
              <ScrollTo
                to='#form-yellowFi'
                section='dobra_01'
                sectionName={ pageText.title }
                elementName={pageText.textCTA}
              >
                <button
                  className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0 mw-100 text-none'
                >
                  {pageText.textCTA}
                </button>
              </ScrollTo>
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default HeroYellowFi
